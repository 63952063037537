<template>
  <div class="submit-form">
    <div v-if="!submitted">
      <div class="form-group">
        <label for="hour">Hora</label>
        <div style="display:flex">
        <input
          type="number"
          class="form-control"
          id="hour"
          required
          v-model="schedule.hour"
          name="hour"
          style="width:9em;flex-grow:1"
        />
        <input
          type="number"
          class="form-control"
          id="minute"
          required
          v-model="schedule.minute"
          name="hour"
          style="width:9em;flex-grow:1"
        />
        </div>
      </div>

      <div class="form-group">
        <label><input type="checkbox" id="day0" v-model="schedule.day0" name="day0"/> Domingo</label><br/>
        <label><input type="checkbox" id="day1" v-model="schedule.day1" name="day1"/> Segunda</label><br/>
        <label><input type="checkbox" id="day2" v-model="schedule.day2" name="day2"/> Terça</label><br/>
        <label><input type="checkbox" id="day3" v-model="schedule.day3" name="day3"/> Quarta</label><br/>
        <label><input type="checkbox" id="day4" v-model="schedule.day4" name="day4"/> Quinta</label><br/>
        <label><input type="checkbox" id="day5" v-model="schedule.day5" name="day5"/> Sexta</label><br/>
        <label><input type="checkbox" id="day6" v-model="schedule.day6" name="day6"/> Sábado</label>
      </div>

      <button @click="saveSchedule" class="btn btn-success">Enviar</button>
    </div>

    <div v-else>
      <h4>Salvo com sucesso!</h4>
      <button class="btn btn-success" @click="newSchedule">Inserir novamente</button>
    </div>
  </div>
</template>

<script>
import ScheduleDataService from "../services/ScheduleDataService";

export default {
  name: "add-schedule",
  data() {
    return {
      schedule: {
        id: null,
        hour: "",
        minute: "",
        day0: true,
        day1: true,
        day2: true,
        day3: true,
        day4: true,
        day5: true,
        day6: true,
        active: true
      },
      submitted: false
    };
  },
  methods: {
    saveSchedule() {
      var data = {
        hour: 0|this.schedule.hour,
        minute: 0|this.schedule.minute,
        day0: this.schedule.day0,
        day1: this.schedule.day1,
        day2: this.schedule.day2,
        day3: this.schedule.day3,
        day4: this.schedule.day4,
        day5: this.schedule.day5,
        day6: this.schedule.day6
      };

      ScheduleDataService.create(data)
        .then(response => {
          this.schedule.id = response.data.id;
          console.log(response.data);
          this.submitted = true;
        })
        .catch(e => {
          console.log(e);
        });
    },
    
    newSchedule() {
      this.submitted = false;
      this.schedule = {};
    }
  }
};
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
</style>
